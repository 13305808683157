import { template as template_12e4f82fa88f4366b0c70bc7734799f9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_12e4f82fa88f4366b0c70bc7734799f9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
