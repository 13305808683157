import { template as template_22ed9873dac048e2ab436560372b5d64 } from "@ember/template-compiler";
const FKLabel = template_22ed9873dac048e2ab436560372b5d64(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
