import { template as template_85eac75528bb4ca7ae2fae43f242f913 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_85eac75528bb4ca7ae2fae43f242f913(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
