import { template as template_f1ccf03f4faa41f5a267c247c2b68c84 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f1ccf03f4faa41f5a267c247c2b68c84(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
