import { template as template_bb870c5d9598493da9764fb2740aa28a } from "@ember/template-compiler";
const WelcomeHeader = template_bb870c5d9598493da9764fb2740aa28a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
